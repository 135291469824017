<template>
<ContenedorCampos  :titulo="sLabel" :isObligatorio="bObligatorio?'1':'0'" :error="error" class="under-sec">
    <div class="my-2">
        <v-row>
            <v-col cols="6" v-for="(foto, index) in aFotos" :key="index">
            <img :src="foto.Base64" width="100%" @click="ampliar(foto)">
            <v-btn small block color="red darken-2" class="mt-n2" @click="borrar(index)">
                <v-icon small dark color="white">mdi-delete</v-icon>
            </v-btn>
            </v-col>   
        </v-row>  
    </div>
    <div v-show="aFotos.length<5">
        <Camera v-if="!getisAPP" @fotoCapture="verFoto"></Camera>
        <CameraAPP v-else @fotoCapture="verFoto"></CameraAPP>
    </div>
    <!-- MODAL PARA AMPLIAR FOTOS -->
    <AmpliarFoto 
    :fotoampliada="ftampliada" 
    :textoampliado="txtampliado" 
    v-model="showFoto"
    ></AmpliarFoto>
</ContenedorCampos>
</template>
<script>
import ContenedorCampos from '@/components/dinamicos/ContenedorCampos.vue'
import DinamicosMixin from '@/mixins/dinamicosMixin.js'
import Camera from '@/components/dinamicos/compAuxiliares/camera.vue'
import AmpliarFoto from '@/components/dinamicos/compAuxiliares/AmpliarFoto.vue'
import CameraAPP from '@/components/dinamicos/compAuxiliares/cameraAPP.vue'
import { mapGetters } from 'vuex'

export default {
    name: 'FormFoto',
    mixins: [ DinamicosMixin ], 
    props:{
        oAtributos:{
            type:Object,
            default:()=>{}
        },
        bObligatorio:{
            type: Boolean,
            default: false,
        },
        sLabel:{
            type: String,
            default: "",
        },
    },
    components: {
        ContenedorCampos,
        Camera,
        AmpliarFoto,
        CameraAPP,
    },  
    data () {
        return {
            aFotos : [],
            ftampliada : null,
            txtampliado : null,
            showFoto : false
        }
    },
    mounted () {
    },
    methods: {
    verFoto(fotos){
        this.aFotos.push(fotos)
    },
    borrar(index) {
        this.aFotos.splice(index, 1)
    },
    ampliar(val){
        this.showFoto = true
        this.ftampliada = val.Base64
        //this.txtampliado = val.NombreFoto
    },
    },
    computed: {
    ...mapGetters(['getisAPP'])
    },
    watch: {
    aFotos(newFotos){
        this.selectValue = newFotos

    }
    }
}
</script>

<style lang="scss" scoped>
</style>