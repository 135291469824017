<template>
    <div class="margen-form">
        <v-container :style="{ marginBottom : (!getbMenuBlock) ? '140px' : '90px'}" >
            <!--Seccion titulo-->
            <v-col class="pa-0 mb-2" cols="12">    
                <HeaderVista :sTitulo="sTitulo" sColor="text-medium text-azul">
                    <template v-slot:icono>
                        <v-img src="/img/icons/perfil-Turnos-512x512.png" contain class="imagen-icono"/>
                    </template>
                </HeaderVista>
            </v-col>
            <!--Seccion formulario-->
            <v-col class="pa-0 mb-2" cols="12">
                <v-card class="no-shadow-card d-flex">
                    <v-col no-gutters class="pa-2">
                        <FormNumero
                            v-model ="sCedula"
                            :bObligatorio = "true"
                            :sLabel = "$STR.lblCedulaCiudadania"
                            :oAtributos = "oAtributosCampoCc"
                        />
                    </v-col>
                </v-card>
            </v-col>
            <v-col class="pa-0 mb-2" cols="12">
                <v-btn 
                    block 
                    depressed
                    color="primary"
                    :disabled = "sCedula == '' || getisOnline==false"
                    @click="onEnviar"
                >
                   {{ $STR.btnVerificar }}
                </v-btn>
            </v-col>
        </v-container>
        <ModalUsuarioRegistrado 
            v-model="bModalDuplicados" 
            :sTipoTurno="sTipoTurno"
            :sCedula = "sCedula"
            @onCerrarModal = "bModalDuplicados = $event"
        />
        <ModalSinInternet
            :sLabelBoton="'Reintentar'"
            :fAccion="() => {}"
        />
        <Snackbar 
            v-model="oModalMensaje.bModalVisible"
            :sTitulo="oModalMensaje.sTitulo"
            :sMensaje="oModalMensaje.sMensaje"
            :sColor="oModalMensaje.sColor"
            :sIcono="oModalMensaje.sIcono"
            :aPosicion="['top']"
            :nDuracion="5000"
            @onClose="()=>oModalMensaje.bModalVisible=false"
        />
    </div>
</template>
<script>
    import HeaderVista from '@/components/HeaderVista.vue'
    import FormNumero from '@/components/Form/Componentes/FormNumero.vue'
    import ModalUsuarioRegistrado from '@/components/control-actividades/turnos/ModalUsuarioRegistrado.vue'
    import Snackbar from '@/components/Snackbar.vue'
    import { mapGetters } from 'vuex'
    import TurnosRepository from '@/views/control-actividades/turnos/TurnosRepository.js'
    import ModalSinInternet from '@/components/ModalSinInternet.vue'

    export default {
        name: 'VerificarTurno',
        mixins: [TurnosRepository],
        components: {
            HeaderVista,
            FormNumero,
            ModalUsuarioRegistrado,
            ModalSinInternet,
            Snackbar
        },
        data() {
            return {
                sTipoTurno: '',
                sTitulo: '',
                sCedula: '',
                oAtributosCampoCc: { 
                    placeholder: this.$STR.msjCedulaCiudadania,
                },
                bModalDuplicados: false,
                oModalMensaje:{
                    bModalVisible:false,
                    sMensaje: '',
                    sTitulo: '',
                    sColor: '#019578',
                    sIcono: 'mdi-check'
                },
            }
        },
        computed: {
            ...mapGetters(['getbMenuBlock', 'getisOnline']),
        },
        methods: {
            async onEnviar(){
                try {
                    const oResponse = await this.onValidarUsuario(this.sCedula,this.sTipoTurno)
                    switch (oResponse.nStatusCode) {
                        case 201:
                            this.bModalDuplicados = true
                            break
                        
                        case 200:
                            this.onCargarVistaRegistrar([oResponse.aData,this.sCedula],this.sTipoTurno,this.$router)
                            break
                    
                        default:
                            break
                    }
                } catch (error) {
                    return
                }
            },   
        },
        mounted() {
            this.sTipoTurno = this.$route.params.sTipoTurno
            this.sTitulo = this.sTipoTurno == 'Arribo' ? this.$STR.ttlTurnoEntrada : this.$STR.ttlTurnoSalida
            const { sTitulo, sMensaje } = this.$route.query;
            if (sTitulo && sMensaje) {
                this.oModalMensaje.bModalVisible = true;
                this.oModalMensaje.sTitulo = sTitulo;
                this.oModalMensaje.sMensaje = sMensaje;
            }
        },
    }
</script>
<style lang="scss">
    .text-azul {
        color: #003743;
    }
    .imagen-icono {
        width: 32px;
    }
    .no-shadow-card {
        box-shadow: none !important;
    }
</style>