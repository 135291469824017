<template>
    <div>
        <span class="text-label-primary">
            {{ sLabel }} <span v-if="bObligatorio" class="red--text">*</span>
        </span>
        <v-textarea
            v-model="valor"
            v-bind="oAtributos"
            :rows="nRow"
            :auto-grow="bAutoGrow"
            :counter="nLength"
            :maxlength="nLength"
            class="mt-1"
            outlined
            dense
        />
    </div>
  </template>
<script>
    export default {
        name: 'FormTextArea',
        props: {
            value: {
            },
            oAtributos:{
                type:Object,
                default:()=>{}
            },
            nLength: {  
                type: Number,
                default: 3000
            },
            bObligatorio:{
                type: Boolean,
                default: false,
            },
            sLabel:{
                type: String,
                default: "",
            },
        },
        data() {
            return {
                valor   : this.value,
                bAutoGrow : true,
                nRow: 3,
            }
        },
        watch: {
            value(NewValue){
                this.valor = NewValue
                if(this.valor.length >=500){
                    this.bAutoGrow = false
                }else{
                    this.bAutoGrow = true
                }
            },
            valor(NewValor) {
                this.$emit('input', NewValor)
            },
        },
    }
</script>
<style scoped>
</style>