<template>
  <v-card
    outlined
    width="100%"
  >
    <v-col>
      <v-banner>
        <v-card-title class="pa-0 my-0 text-size-1">
         {{ oPregunta.nIndex + 1}}. {{ oPregunta.sPregunta }} 
         <span v-if="oPregunta.nObligatorio == 1" class="red--text font-weight-regular ml-1">*</span>
        </v-card-title>
      </v-banner>
      <v-radio-group
        row
        v-model="valor"
      >
        <v-row no-gutters class="align-center"  v-for="item,index in aValores"
        :key="index">
          <v-radio 
            class="respuesta mx-auto"
            :key="index"
            :label="item.texto"
            :value="item.id"
            @click="onCheckRespuesta(item)"
          />
        </v-row>
      </v-radio-group>
      <div v-if="oRespuesta.valorRespuesta == 0">
        <v-row no-gutters class="align-center" >
          <v-textarea
            v-model="sValorObservacion"
            :placeholder="$STR.cmpAgregarObservaciones"
            rows="3"
            row-height="10"
            outlined
            required
          />
          
        </v-row>
        <v-row no-gutters class="mt-1 mb-3">
          <DictadoPorVoz  @onDictado="onDictadoVoz"/>
        </v-row>
        <v-row no-gutters>
          <Fotos
            v-model="aFotos"
            :sTitulo='$STR.lblAnexos'
            :nCantidadFotos="1"
          />
        </v-row>
      </div>

    </v-col>
  </v-card>
</template>

<script>
import Fotos from '@/components/Fotos.vue'
import DictadoPorVoz from '@/components/DictadoPorVoz.vue'

export default {
  name: 'PreguntaPuntoItemCard',
  components: {
    Fotos,
    DictadoPorVoz
  },
  props: {
    oPregunta: {
      type: Object,
      default:()=> {}
    },
    aValores: {
      type: Array,
      default:() => [
        {id:0, texto:"Con novedad"}, 
        {id:1, texto:"Sin novedad"}, 
        {id:2, texto:"N/A"}
      ]
    },
  },
  data() {
    return {
      oValor:{},
      aFotos:[],
      sValorObservacion: '',
      oRespuesta: {
        idPregunta: 0, 
        textoRespuesta: 'Sin responder', 
        valorRespuesta: 3, 
        sObservacion: '', 
        fotoAnexo: '',
        nObligatorio: 1, 
      }
    };
  },
  computed: {

  },
  mounted() {
    this.onLimpiarObservaciones()
    this.$emit("input", this.oRespuesta);
  },
  methods: {
    onDictadoVoz(sDictadoVoz){
      console.log(sDictadoVoz);
      this.sValorObservacion = sDictadoVoz
    },
    onCheckRespuesta(valor){
      this.oValor = valor
      if(valor.id != 3){
        this.onLimpiarObservaciones()
      }
      this.valor = valor.id
    },
    onLimpiarObservaciones(){
      this.oRespuesta.idPregunta= this.oPregunta.idPregunta
      this.oRespuesta.textoRespuesta= 'Sin responder'
      this.oRespuesta.valorRespuesta = 3
      this.oRespuesta.sObservacion = ''
      this.oRespuesta.fotoAnexo = {}
      this.oRespuesta.nObligatorio = this.oPregunta.nObligatorio
      this.sValorObservacion = ''
      this.aFotos = []
    },
    onRespuesta(){
      this.oRespuesta.idPregunta= this.oPregunta.idPregunta, 
      this.oRespuesta.textoRespuesta= this.oValor.texto, 
      this.oRespuesta.valorRespuesta = this.oValor.id, 
      this.oRespuesta.sObservacion =  this.sValorObservacion,
      this.oRespuesta.nObligatorio = this.oPregunta.nObligatorio
      this.oRespuesta.fotoAnexo = (this.aFotos.length > 0) ? this.aFotos[0] : {}
      this.$emit("input", this.oRespuesta);
    }
  },
  computed: {
    valor: {
      get() {
        return this.oRespuesta.valorRespuesta
      },
      set(newValue) {
        this.onRespuesta()
      },
    },
  },
  watch:{
    aFotos(newFotos){
      if(newFotos.length>0) this.onRespuesta()
    },
    sValorObservacion(newSobservacion){
      if(newSobservacion.length>0) this.onRespuesta()
    }
  }
};
</script>

<style scoped>
.respuesta::v-deep .v-label {
  font-size: 0.8em !important;
}


</style>
