<template>
    <div>
      <v-snackbar
        :color="sColor"
        elevation="12"
        :multi-line="true"
        v-model="valor"
        :timeout="nDuracion"
        :top="oPosiciones.top"
        :bottom="oPosiciones.bottom"
        :right="oPosiciones.right"
        max-width="600"
        :left="oPosiciones.left"
      >
        <v-row class="no-gutters">
          <v-col cols="1">
            <v-icon>{{ sIcono }}</v-icon>
          </v-col>
          <v-col class="mx-2">
            <v-row>
              <div class="text-center">
                <b>{{ sTitulo }}</b>
              </div>
            </v-row>
            <v-row>
              <div class="text-left">
                {{ sMensaje }}
              </div>
            </v-row>
          </v-col>
          <v-col cols="1" align="right">
            <v-btn icon @click="onCerrar">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-snackbar>
    </div>
  </template>
  <script>
  export default {
    name: "Snackbar",
    props: {
      value: {
        type: Boolean,
        default: false,
      },
      sIcono: {
        type: String,
        default: "mdi-check",
      },
      sColor: {
        type: String,
        default: "success",
      },
      sTitulo: {
        type: String,
        default: "",
      },
      sMensaje: {
        type: String,
        default: "",
      },
      nDuracion: {
        type: Number,
        default: -1,
      },
      aPosicion: {
        type: Array,
        default: () => [],
      },
    },
    data() {
      return {
        text: "",
        multiLine: true,
        oPosiciones: {
          top: true,
          bottom: false,
          right: false,
          left: false,
        },
      };
    },
    mounted() {
      //Inicializamos posiciones en false
      this.oPosiciones.top = false;
      this.oPosiciones.bottom = false;
      this.oPosiciones.right = false;
      this.oPosiciones.left = false;
  
      if (this.aPosicion.length > 0) {
        this.aPosicion.forEach((element) => {
          if (Object.prototype.hasOwnProperty.call(this.oPosiciones, element)) {
            this.oPosiciones[element] = true;
          }
        });
      } else {
        this.oPosiciones.top = true;
      }
    },
    methods: {
      onCerrar() {
        this.$emit('input', false); 
        this.$emit('onClose');
      }
    },
    watch: {},
    computed: {
      valor: {
        get() {
          return this.value;
        },
        set(newValue) {
          this.$emit("input", newValue);
        },
      },
    },
  };
  </script>