<template>
    <div class="my-3">
      <tituloLabel :sLabel="sLabel"/>
      <!-- listado de datos diligenciados -->
      <v-row>
        <v-col  v-for="oListaForm in aListadoDiligenciado" cols="12" class="mb-3">
          <v-card elevation="2">
            <v-row>
              <v-col cols="10">
                <v-row v-for="oItemFom in oListaForm.aData" class="py-0">
                  <!-- Validamos si es una foto para no mostrarlos -->
                  <template v-if="onValidarDatoMostrar(oItemFom)">
                    <v-col cols="5" class="pl-5 py-1">{{ oItemFom.sTitulo }}</v-col>
                    <v-col cols="7" class="py-1">{{ onMostrarData(oItemFom) }}</v-col>
                  </template>
                </v-row>
              </v-col>
              <v-col cols="2">
                <v-btn icon color="red" class="ml-auto" @click="onEliminarRegistro(oListaForm)">
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <div style="display: flex; justify-content: flex-end;">
            <v-btn
              class="mx-2 my-2"
              fab
              dark
              color="#63A9E7"
              @click="onMostrarFormulario"
              small
            >
              <v-icon dark>
                mdi-plus
              </v-icon>
            </v-btn>
          </div>
        </v-col>
      </v-row>

      <v-row>
        <v-dialog
          v-model="bSubFormulario"
          fullscreen
          hide-overlay
          transition="dialog-bottom-transition"
        >
          <v-card>
            <v-toolbar dark color="#63A9E7">
              <v-btn icon dark @click="bSubFormulario = false">
                <v-icon>mdi-arrow-left</v-icon>
              </v-btn>

              <v-spacer></v-spacer>
              <v-toolbar-title class="text-center">{{ sLabel }}</v-toolbar-title>
              <v-spacer></v-spacer>
            </v-toolbar>
            <v-card-text class="pt-4">
              <v-col :cols="campo.cols"  v-for="(campo, index) in aFormulario" :key="index +campo.AsValorEnviar +nKeyCleanCampos"  class="pa-1 ma-0">
                <component 
                  :key="nKeyCleanCampos"
                  :ref="`form-${campo.AsValorEnviar}`"
                  :is="campo.Tipo" 
                  v-model="oFormCampos[campo.AsValorEnviar]"
                  :sLabel="getEquivalencia(campo.Label)"
                  :bObligatorio="campo.Obligatorio == 1 ? true : false"
                  :sCondicion="campo.Condicion"
                  :sCampoFiltro="campo.Filtro"
                  :nFiltro="campo.nFiltro"
                  :valorFiltro="campo.ValorFiltro"
                  :sCampoModificaA="campo.ModificaA"
                  :sCampoItem="campo.Campo"
                  :sIdItem="campo.idValorSeleccionar"
                  @modificaA="onModificarCampo"
                  :sTabla="campo.sTabla"
                  :oDataExtra = "campo"
                  />
              </v-col>
              <v-col cols="12" class="pa-1 my-4">
                <v-btn block @click="onAgregarSubFormulario" class="white--text" color="#63A9E7">Agregar</v-btn>
              </v-col>
              <modalError v-model="bModalError" :aListaErrores="aListaErrores"/>
            </v-card-text>
            
          </v-card>
        </v-dialog>
      </v-row>
    </div>
</template>
  
<script>
import selector from '@/components/Form/Componentes/FormSelect.vue';
import texto from '@/components/Form/Componentes/FormTexto.vue';
import tituloLabel from '@/components/Form/Componentes/FormLabelTitulo.vue';
import copiaCorreo from '@/components/Form/Componentes/FormCorreo.vue';
import modalError from '@/components/Form/Componentes/ModalError.vue';
import descripcionLabel from '@/components/Form/Componentes/FormLabelDescripcion.vue';
import foto from '@/components/Form/Componentes/FormFoto.vue';
import firma from '@/components/Form/Componentes/FormFirma.vue';
import numero from '@/components/Form/Componentes/FormNumero.vue';
import {mapGetters} from 'vuex'


export default {
    name: 'FormSub',
    components: {
      tituloLabel,
      selector,
      texto,
      copiaCorreo,
      descripcionLabel,
      modalError,
      foto,
      firma,
      numero
    },
    props: {
      sLabel:{
          type: String,
          default: "",
      },
      oDataExtra:{
        type: Object,
        default: () => {}
      }
    },
    data() {
      return {
        bSubFormulario:false,
        aFormulario:[],
        aCampos:[],
        nKeyCleanCampos:0,
        oFormCampos:{},
        bModalError:false,
        aListaErrores:[],
        aListadoDiligenciado:[],
        nIdForm:0
      }
    },
    async mounted(){
      this.aFormulario = this.oDataExtra.aSubFormulario;
      await this.getCampos();
      await this.cargarFormulario();
    },
    methods:{
      onMostrarData(oDato){
        //Validamos si viene tabla para mostrar la info
        if(oDato?.idSubTipoCampoApp&&oDato.idSubTipoCampoApp=="4"){
          //Consultamos la tabla a trabajar
          let aTabla = this.$store.state.dataBase.dataBase?.find(item => item.tablaName==oDato.sTabla)?.info;
          
          //Validamos si es indefinido es pq es de la tabalGeneral
          if(aTabla==undefined){
            let aTablaGeneral = this.$store.state.dataBase.dataBase.find(item => item.tablaName=="TablasGeneralesApp")?.info;
            if(aTablaGeneral!=undefined){
              aTabla = aTablaGeneral?.filter(item => item.NombreTabla==oDato.sTabla);
            }
          }
          let oResult = aTabla?.find(item => item[oDato.idValorSeleccionar] == oDato.sDato);
          
          if(oResult[oDato.Campo]){
            return oResult[oDato.Campo];
          }
        }
        //Validamos si es una foto para solo mostrar la cantidad
        else if(oDato.idSubTipoCampoApp&&(oDato.idSubTipoCampoApp=="9"||oDato.idSubTipoCampoApp=="10")){
          if(oDato.sDato?.TextoRespuesta!=undefined && oDato.sDato?.TextoRespuesta[0]?.Base64){
            return oDato.sDato?.TextoRespuesta?.length;
          }
          return 0;
          
        }
        return oDato.sDato;
      },
      onValidarDatoMostrar(oDato){
        // if(oDato.sDato?.TextoRespuesta!=undefined && oDato.sDato?.TextoRespuesta[0]?.Base64){
        //   return false;
        // }
        //Validamos que no sea un label
        if(oDato.idSubTipoCampoApp!=undefined && (oDato.idSubTipoCampoApp=="6"||oDato.idSubTipoCampoApp=="5")){
          return false;
        }
        return true;
      },
      onEliminarRegistro(oDato){
        this.aListadoDiligenciado = this.aListadoDiligenciado.filter(item => item.nIdForm!=oDato.nIdForm);
        this.$emit('input',this.aListadoDiligenciado);
      },
      onMostrarFormulario(){
        this.nKeyCleanCampos++;
        this.oFormCampos={};
        this.bSubFormulario = true;
      },
      async cargarFormulario(){
        //Validamos que venga un idmovil 
        if(this.idMovilApp==0){
          return;
        }
        
        this.aFormulario = this.aFormulario.sort((a, b) => a.Orden - b.Orden);

        for (let i = 0; i < this.aFormulario.length; i++) {
          this.aFormulario[i].cols =  this.aFormulario[i].Tamano; 
          this.aFormulario[i].sTabla =  this.aFormulario[i].Tabla; 
          this.aFormulario[i].Tipo = this.aCampos.find(item => item.idTipoCampoApp == this.aFormulario[i].idSubTipoCampoApp)?.Tipo;

        }
      },
      getCampos(){
        this.aCampos = this.$store.state.dataBase.dataBase.find(item => item.tablaName=='TipoCampoApp')?.info;
      },
      getEquivalencia(sEquivalencia){
        
        if(sEquivalencia?.includes("??")){
          sEquivalencia = sEquivalencia.replaceAll("??", "");
          sEquivalencia = this.getoEquivalencia[sEquivalencia];
        }
        return sEquivalencia;
      },
      onModificarCampo(oDato){
        //Consultamos del formulario el dato al que le vamos a pasar el filtro
        let aFormularioTemp = this.aFormulario.find(item => item.sTabla == oDato.sCampoModificaA);
        if(aFormularioTemp!=undefined){
          aFormularioTemp.nFiltro = oDato.nId;
        }
        
      },
      onAgregarSubFormulario(){
        //Validamos que los campos que sean obligatorios esten llenos
        this.aListaErrores =  [];
        let bAlgunPendiente = false;
        let aDataInsertar=[];

        this.aFormulario.forEach(oFormTemp => {
          let sLabelTemp = this.getEquivalencia(oFormTemp.Label);
          let bInserta = true;

          if(oFormTemp.Obligatorio=="1"){
            //Validamos que el campo no este vacio
            if(this.oFormCampos[oFormTemp.AsValorEnviar]==""||this.oFormCampos[oFormTemp.AsValorEnviar]==undefined){
              bAlgunPendiente=true;
              bInserta=false;
              this.aListaErrores.push(sLabelTemp);
            }
          }

          //Checamos si se inserta o no 
          if(bInserta){
            aDataInsertar.push({
              sTitulo:sLabelTemp,
              sDato:this.oFormCampos[oFormTemp.AsValorEnviar],
              AsValorEnviar:oFormTemp.AsValorEnviar,
              sTabla:oFormTemp?.sTabla,
              idValorSeleccionar:oFormTemp?.idValorSeleccionar,
              Campo:oFormTemp?.Campo,
              idSubTipoCampoApp:oFormTemp?.idSubTipoCampoApp
            })
          }
        });

        //validamos si mostramos los errores
        if(bAlgunPendiente){
          this.bModalError=true;
        }
        else{
          //Agreamos al listado de los datos que han diligenciado
          this.aListadoDiligenciado.push({
            nIdForm:this.nIdForm,
            aData:aDataInsertar
          });
          this.nIdForm++;
          //Limpiamos el formulario
          this.oFormCampos={};
          this.bSubFormulario=false;
          this.$emit('input',this.aListadoDiligenciado);
        }
      }
    },
    watch:{
      oDataExtra(){
        this.aFormulario = this.oDataExtra.aSubFormulario
      }
    },
    computed: {
      ...mapGetters(['getoEquivalencia']),
    },
}
</script>
<style>
.text-azul {
        color: #003743;
    }
</style>