<template>

  <DialogMensaje
  v-model="bSinConexion"
  :texto="$STR.msgSinInternet"
  :bOcultarCancelar="true"
  :textoBtn="sLabelBoton"
  @onAceptar="fAccion"
  >
  <template v-slot:icono>
      <v-row no-gutters justify="center" class="ma-2">
      <lottie-vue-player
          :src="fIconoSinIntJson"
          player-size="standard"
          :player-controls="false"
          :autoplay="true"
          :loop="false"
          style="width: 100%; height:120px"
          >
      </lottie-vue-player>
      </v-row>
  </template>
  </DialogMensaje>

</template>

<script>
import DialogMensaje from '@/components/DialogMensaje.vue'
import IconoSinInternet from "@/assets/ic_sin_internet.json"
import { mapGetters } from 'vuex'

export default {
  name: "ModalSinInternet",
  props: {
    sLabelBoton:{
      type: String,
      default: 'Aceptar'
    },
    fAccion:{
      type: Function,
      default: () => {}
    }
  },
  components: {
    DialogMensaje
  },
  data() {
    return {
      bSinConexion: false,
      fIconoSinIntJson:JSON.stringify(IconoSinInternet),
    }
  },
  mounted() {

  },
  computed: {
    ...mapGetters(['getisOnline'])
  },
  watch: {
    getisOnline(newValue) {
      this.bSinConexion = !newValue
      //console.log("Conexion", this.bSinConexion );
    },
  },
};
</script>