<template>
    <div>
        <v-dialog v-model="value" persistent>
            <v-card>
                <v-card-title class="d-flex justify-between align-center">
                    <h3 class="font-weight-medium mx-auto">
                        {{ onTituloModal }}
                    </h3>
                    <v-btn 
                        icon 
                        outlined 
                        color="#EFF1F5"
                        @click="onCancelar"
                    >
                        <v-icon  color="#003743" size="20px">mdi-close</v-icon>
                    </v-btn>
                </v-card-title>
                <v-divider/>
                <v-card-text class="mt-2">
                    <v-container> 
                        <v-row class="mb-1 custom-alert">
                            <v-col class="d-flex">
                                <v-icon class="mr-2" color="#F96357">mdi-information-outline</v-icon>
                                <span> {{ onMensajeModal.replace("xxx", sCedula) }}</span>
                            </v-col>
                        </v-row>
                        <v-row>
                            <div class="mx-auto">
                                <v-btn color="btncolor" outlined @click="onCancelar">
                                    {{ $STR.btnCancelar}}
                                </v-btn>
                            </div>
                        </v-row>
                    </v-container>
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
    export default {
        name: "ModalUsuarioRegistrado",
        props: {
            sTipoTurno: {    
                type: String,
                default: "",
            },
            sCedula: {    
                type: String,
                default: "",
            },
            value: {
                type: Boolean,
                default: false,
            },
        },
        computed: {
            onTituloModal() {
                return this.sTipoTurno == 'Arribo' ? this.$STR.ttlArriboDuplicado : this.$STR.ttlSalidaDuplicada
            },
            onMensajeModal() {
                return this.sTipoTurno == 'Arribo' ? this.$STR.msjArriboDuplicado : this.$STR.msjSalidaDuplicado
            }
        },
        methods: {
            onCancelar() {
                this.$emit('onCerrarModal',false)
            },
        },
    }
</script>
<style lang="scss" scoped>
    .custom-alert {
        border-left: 5px solid #F96357;   
        background-color: #F5E4E3;
    }
</style>
