<template>
  <v-container fluid class="cont-pagina">
    <v-row class="no-gutters">
      <v-col cols="12">
        <h3 class="titulo-contendor text-center py-3">Registro de contexto interno y externo</h3>
      </v-col>
    </v-row>
    <v-row class="no-gutters">
      <v-col>
        <Form 
        :idMovilApp="31"
        titulo="Registro de contexto interno y externo"
        />
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import mxFuncionesBasicas from "@/mixins/FuncionesBasicas.js";
// import { mapGetters } from 'vuex';
import Form from "@/components/Form/Form.vue";

export default {
  name: 'contextoIntExt',
  mixins: [mxFuncionesBasicas],
  components:{
    Form
  },
  data() {
    return {
      
    }
  },
  methods: {
  },
  mounted() {
  },
  computed: {
  }
}
</script>
<style lang="scss">
tbody{
  tr{
    td{
      font-size: 11px!important;
      min-height: 30px!important;
    }
  }
}
.v-icon.v-icon{
  font-size: 18px;
}
.boton-mas{
  color: blue;
}
.cont-item-pag{
  transform: scale(0.8);
}
.text-registros{
  font-size:12px;
  text-align: left;
}
</style>