<template>
  <div class="contenedor-informacion">
    <v-expansion-panels :value="(value) ? 0 : false">
      <v-expansion-panel>
        <v-expansion-panel-content>
            <!--Tabs-->
            <v-card elevation="2" class="mb-1">
              <v-tabs  v-model="tab" centered> 
                <v-tab :href="`#tabDescripcion-${sIdPunto}`" >
                  <v-icon large>mdi mdi-text-box-outline</v-icon>
                </v-tab>
                <v-tab :href="`#tabFoto-${sIdPunto}`">
                  <v-icon large>mdi mdi-camera-outline</v-icon>
                </v-tab>
              </v-tabs>
            </v-card>
             <!--Contenido tabs-->
            <v-tabs-items v-model="tab">
                <v-tab-item :value="`tabDescripcion-${sIdPunto}`">
                    <v-card flat>
                        <v-card-text class="ma-2 black--text">{{ sDescripcion }}</v-card-text>
                    </v-card>
                </v-tab-item>
                <v-tab-item :value="`tabFoto-${sIdPunto}`" class="pa-0">
                  <v-col justify="center" align="center" class="pa-0 mb-5">
                    <v-icon v-if="sFoto== '' || sFoto == null"  x-large >mdi mdi-image-off</v-icon>
                    <v-img v-else
                      height="180"
                      width="250"
                      contain
                      :src="sFoto"
                    >
                      <template v-slot:placeholder>
                        <v-row class="fill-height ma-0" align="center" justify="center">
                          <v-progress-circular indeterminate color="primary"/>
                        </v-row>
                      </template>
                    </v-img>
                  </v-col>
                </v-tab-item>
            </v-tabs-items>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>
<script>
import Imagen from '@/components/dinamicos/campos/Imagen.vue'

export default {
  name: 'InformacionPunto',
  components: {Imagen},
  props: {
    value: Boolean,
    sIdPunto: {
        type: String,
        required: true
    },
    sDescripcion: {
        type: String,
        required: true
    },
    sFoto: {
        type: String,
        required: true
    },
  },
  data() {
    return {
      tab:null
    };
  },
}
</script>
<style lang="scss" scoped>
  .icon-circle {
      border-radius: 50%;
      background-color: #F8F8F8; 
      padding: 6px; 
      font-size: 18px; 
  }
  .contenedor-informacion{
    width: 100%;
  }
  :deep(.v-expansion-panel-content__wrap) {
      padding: 0 0px 0px !important;
  }
</style>  