<template>
    <div class="margen-form">
        <v-container :style="{ marginBottom : (!getbMenuBlock) ? '140px' : '90px'}" >
            <!--Seccion titulo-->
            <v-col class="pa-0 mb-2" cols="12">
                <HeaderVista :sTitulo="sTitulo" sColor="text-medium text-azul">
                    <template v-slot:icono>
                        <v-img src="/img/icons/perfil-Turnos-512x512.png" contain class="imagen-icono"/>
                    </template>
                </HeaderVista>
            </v-col>
            <!--Seccion datos basicos Normal-->
            <v-col class="pa-0 mb-2" cols="12" v-if="getaDataOperario[0]?.NombreOperario">
                <v-card class="no-shadow-card d-flex">
                    <DatosAsociados/>
                </v-card>
            </v-col>
            <!--Seccion datos basicos Nuevo-->
            <v-col class="pa-0 mb-2" cols="12" v-else>
                <v-card class="no-shadow-card d-flex">
                    <v-container>
                        <v-col no-gutters class="pa-2">
                            <h3 class="font-weight-medium text-azul">{{ $STR.lblDatosBasicos }}</h3> 
                        </v-col>
                        <v-col no-gutters class="pa-2">
                            <FormNumero
                                v-model ="sCedula"
                                :bObligatorio = "true"
                                :sLabel = "$STR.lblCedulaCiudadania"
                                :oAtributos = "oAtributos.cedula"
                            />
                         </v-col>
                        <v-col no-gutters class="pa-2">
                            <FormTexto
                                v-model ="sNombre"
                                :bObligatorio = "true"
                                :sLabel = "$STR.lblNombre"
                                :oAtributos = "oAtributos.nombre"
                            />
                        </v-col>
                    </v-container>
                </v-card>
            </v-col>
            <!--Seccion Lugar-->
            <v-col class="pa-0 mb-2" cols="12">
                <v-card class="no-shadow-card d-flex">
                    <v-container>
                        <v-col no-gutters class="pa-2">
                            <h3 class="font-weight-medium text-azul">{{ $STR.lblDatosArribo }}</h3>
                        </v-col>
                        <v-col no-gutters class="pa-2"> 
                            <FormSelect
                                v-model="nCliente"
                                :sLabel="$STR.lblCliente"
                                :bObligatorio = "true"
                                :oAtributos = "oAtributos.cliente"
                                :sCampoItem = "'Nombre_Cliente'"
                                :sIdItem = "'idCliente'"
                                sTabla = "Clientes"
                                :bClearable="oAtributos.cliente.clearable"
                                :sPlaceholder = "oAtributos.cliente.placeholder"       
                            />
                         </v-col>
                         <v-col no-gutters class="pa-2"> 
                            <FormSelect
                                v-model="nSede"
                                :sLabel="$STR.lblSede"
                                :bObligatorio = "true"
                                :sTabla = "oConfiguracion.sTbSede"
                                :sCampoItem = "oConfiguracion.sCampoSede"
                                :sIdItem = "oConfiguracion.sIdSede"
                                :nFiltro = "nCliente"
                                :sCampoFiltro = "oConfiguracion.sIdClienteSede"
                                :oAtributos = "oAtributos.sede"
                                @input="onClearDependientes(['nPuesto'])"
                                :bClearable="oAtributos.sede.clearable"
                                :sPlaceholder = "oAtributos.sede.placeholder"               
                            />
                         </v-col>
                         <v-col no-gutters class="pa-2"> 
                            <FormSelect
                                v-model="nPuesto"
                                :sLabel="$STR.lblPuesto"
                                :bObligatorio = "true"
                                :sTabla = "'Puestos'"
                                :sCampoItem = "'NombrePuesto'"
                                :sIdItem = "'idPuestos'"
                                :nFiltro = "nSede"
                                :sCampoFiltro = "'idSede'"
                                :oAtributos = "oAtributos.puesto"
                                :sPlaceholder = "oAtributos.puesto.placeholder"
                            />
                         </v-col>
                    </v-container>
                </v-card>
            </v-col>
            <!--Seccion Novedades-->
            <v-col class="pa-0 mb-2" cols="12">
                <v-card class="no-shadow-card d-flex">
                    <v-container>
                        <v-col no-gutters class="pa-2">
                            <h3 class="font-weight-medium text-azul">{{ $STR.lblNovedades }}</h3>
                        </v-col>
                        <v-col no-gutters class="pa-2"> 
                            <FormSelect
                                v-model="nNovedad"
                                :sLabel="$STR.lblTipoNovedades"
                                :sTabla = "'CA_TiposNovedades'"
                                :sCampoItem = "'Novedad'"
                                :sIdItem = "'idCA_TipoNovedades'"
                                :sPlaceholder = "oAtributos.novedad.placeholder"
                            />
                        </v-col>
                        <v-col no-gutters class="pa-2" v-if="nNovedad">
                            <FormTextArea
                                :bObligatorio = "true"
                                v-model="sObservacion"
                                :sLabel="$STR.cmpObs"
                                :nLength="500"
                            />
                        </v-col>
                        <v-col class="pa-0" v-if="nNovedad">
                            <Fotos
                                v-model="aFotos"
                                :nCantidadFotos="1"
                            />
                         </v-col>
                    </v-container>
                </v-card>
            </v-col>
            <!--Seccion Acciones-->
            <v-col class="pa-0 mb-2" cols="12">
                <v-btn 
                    block 
                    depressed
                    color="primary"
                    @click="onEnviar"
                    :disabled ="onvalidarCampos"
                >
                   {{ $STR.btnRegistrarConfirmar }} {{ sTipoTurno }}
                </v-btn>
            </v-col>
            <v-col class="pa-0 mb-2" cols="12">
                <v-btn 
                    block 
                    depressed
                    color="error"
                    plain
                    @click="onCancelar"
                    class="text-decoration-underline"
                >
                   {{ $STR.btnCancelar }}
                </v-btn>
            </v-col>
        </v-container>
        <Snackbar 
            v-model="oModalMensaje.bModalVisible"
            :sTitulo="oModalMensaje.sTitulo"
            :sMensaje="oModalMensaje.sMensaje"
            :sColor="oModalMensaje.sColor"
            :sIcono="oModalMensaje.sIcono"
            :aPosicion="['top']"
            :nDuracion="5000"
            @onClose="()=>oModalMensaje.bModalVisible=false"
        />
        <ModalSinInternet
            :sLabelBoton="'Reintentar'"
            :fAccion="() => {}"
        />
    </div>
</template>
<script>
    import HeaderVista from '@/components/HeaderVista.vue'
    import FormNumero from '@/components/Form/Componentes/FormNumero.vue'
    import FormTexto from '@/components/Form/Componentes/FormTexto.vue'
    import FormSelect from '@/components/Form/Componentes/FormSelect.vue'
    import FormTextArea from '@/components/Form/Componentes/FormTextArea.vue'
    import DatosAsociados from '@/components/control-actividades/turnos/DatosAsociados.vue'
    import Snackbar from '@/components/Snackbar.vue'
    import Fotos from '@/components/Fotos.vue'
    import ModalSinInternet from '@/components/ModalSinInternet.vue'
    import { mapGetters } from 'vuex'
    import TurnosRepository from '@/views/control-actividades/turnos/TurnosRepository.js'
    import HoraFecha from "@/utils/Hora_Fecha.js"
    import GPSLocation from '@/utils/GPSlocation.js'
    import APPutils from '@/utils/APPutils.js'

    export default {
        name: 'RegistrarTurno',
        mixins: [TurnosRepository],
        components: {
            HeaderVista,
            FormNumero,
            FormTexto,
            FormSelect,
            FormTextArea,
            Fotos,
            DatosAsociados,
            Snackbar,
            ModalSinInternet
        },
        data() {
            return {
                sTipoTurno: '',
                oDatosOperario: {},
                sTitulo: '',
                sCedula: '',
                sNombre: '',
                nCliente: '-1',
                nSede: '-1',
                nPuesto: '',
                nNovedad: '',
                sObservacion: '',
                aFotos:[],
                oLocation : {},
                bErrorPermisos : false,
                oModalMensaje:{
                    bModalVisible:false,
                    sMensaje: '',
                    sTitulo: '',
                    sColor: '#019578',
                    sIcono: 'mdi-check'
                },
                isMounted: false,
            }
        },
        watch: {
            nCliente(newVal) {
                if (this.isMounted) return // Evitar acción durante la inicialización
                this.onClearDependientes(['nSede', 'nPuesto'])
            },
        },
        computed: {
            ...mapGetters(['getbMenuBlock','getaDataOperario','getisAPP','getisOnline']),
            onvalidarCampos() { //Valida el diligenciamiento de los campos
                if(this.nNovedad != "" && this.sObservacion.trim() === ''){ // Si se selecciona una novedad se espera por lo menos una descripcion
                    return true
                }
                if(!this.getisOnline){
                    return true
                }
                const aCampos = [this.sCedula, this.sNombre, this.nCliente, this.nSede, this.nPuesto]
                return aCampos.some(campo => campo === '' || campo === null)
            },
            onBody(){ // Construye el cuerpo del oBody
                const aNombreFotos = []
                    this.aFotos.forEach( async foto => {
                    aNombreFotos.push({ fotoNombre : foto.NombreFoto})
                })
                return {
                    sMedio: "APP",
                    sFechaEjecucion : HoraFecha.getNow(),
                    sTipoTurno: this.sTipoTurno,
                    sCedula: this.sCedula,
                    sNombre: this.sNombre,
                    nCliente: this.nCliente,
                    nSede: this.nSede,
                    nPuesto: this.nPuesto,
                    nNovedad: this.nNovedad,
                    sObservacion: this.sObservacion,
                    aFotos : aNombreFotos,
                    Latitud : this.oLocation.coords.latitude,
                    Longitud : this.oLocation.coords.longitude,
                    oDatosOperario: this.oDatosOperario,
                }
            }
        },
        methods: {
            async onEnviar(){
                try {
                    const oResponse = await this.onRegistrarTurno(this.onBody, this.aFotos, this.getisAPP)
                    switch (oResponse.nStatusCode) {
                        case 201:
                            //Este caso aplica cuando se quiere registrar en el formulario un usuario existente.
                                this.oModalMensaje.bModalVisible = true
                                this.oModalMensaje.sTitulo = this.$STR.ttlUsuarioRegistrado
                                this.oModalMensaje.sMensaje = this.$STR.msjUsuarioRegistrado.replace("xxx", this.sCedula)
                                this.oModalMensaje.sColor = "#DF4B46"   
                            break
                        
                        case 200:
                            //Cuando la respuesta es exitosa retorna a la vista de verificacion.
                            if(this.getisAPP) {
                                APPutils.registro_OK()
                            }else{
                                const sTitulo = this.sTipoTurno == 'Arribo' ? this.$STR.ttlArriboRegistrado : this.$STR.ttlSalidaRegistrado
                                const sMensaje = this.sTipoTurno == 'Arribo' ? this.$STR.msjArriboRegistrado : this.$STR.msjSalidaRegistrado
                                this.$router.push({
                                    name: "VerificarTurno",
                                    params: { sTipoTurno: this.sTipoTurno },
                                    query: { sTitulo: sTitulo, sMensaje: sMensaje},
                                })
                            }
                            break
                    
                        default:
                            break
                    }
                } catch (error) {
                    return
                }
            },
            onClearDependientes(aCampos){ // Limpia los select dependientes cuando se cambia el valor del principal
                aCampos.forEach(campo => {
                    this[campo] = ''
                })
            },
            onCancelar(){ // Regresa a la vista verificar
                this.$router.push({
                    name: "VerificarTurno",
                    params: { sTipoTurno: this.sTipoTurno }
                })
            }
        },
        async mounted() {
            this.sTipoTurno = this.$route.params.sTipoTurno
            this.oDatosOperario = this.getaDataOperario[0]
            this.sCedula = this.getaDataOperario[1]
            this.sNombre = this.getaDataOperario[0]?.NombreOperario || ''
            this.sTitulo = this.onValidarTituloRegistro(this.sTipoTurno,this.oDatosOperario)
            this.nCliente = this.onGetClienteDefault()
            this.nSede = this.onGetSedeDefault()
            if(this.nSede){ // Permite que se cargue un valor para sede y que no se borre al precargar un cliente
                this.isMounted = true
            }
            //Logica para la captura de la ubicacion
            try{ 
                this.oLocation = await GPSLocation.getCurrentPosition()
                this.bErrorPermisos = false
            }catch(e){
                if(e.message=='User denied Geolocation'){
                    this.bErrorPermisos = true
                }
                // Si hay error capturo el objeto segun la Doc https://developer.mozilla.org/en-US/docs/Web/API/GeolocationPositionError
                APPutils.onGuardarLog("Error GeolocationPositionError: {code:"+e.code+", message:"+e.message+"}")
            } 
        },
    }
</script>
<style lang="scss">
    .text-azul {
        color: #003743;
    }
    .imagen-icono {
        width: 32px;
    }
    .no-shadow-card {
        box-shadow: none !important;
    }
</style>