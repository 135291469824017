<template>
    <v-container>
        <v-col no-gutters class="pa-2">
            <h2 class="font-weight-medium text-azul">{{ $STR.ttlRegistroTurno }}</h2>
        </v-col>
        <v-row class="d-flex align-center">
            <v-col class="pa-4 pb-0" cols="6">
                <v-btn 
                    block 
                    dark
                    depressed
                    color="#019578"
                    @click=" onRegistrarTurno('Arribo')"
                >
                    <v-icon left>
                        mdi-login
                    </v-icon>
                    {{ $STR.btnEntrada }}
                </v-btn>
            </v-col>
            <v-col class="pa-4 pb-0" cols="6">
                <v-btn 
                    block 
                    depressed
                    color="error"
                    @click=" onRegistrarTurno('Salida')"
                >
                    <v-icon left>
                        mdi-logout
                    </v-icon>
                    {{ $STR.btnSalida }}
                </v-btn>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
    import { mapGetters } from 'vuex'
    export default {
        name: 'ModuloTurnos',
        data() {
            return {
               
            }
        },
        computed: {
            ...mapGetters(['getbMenuBlock']),
        },
        methods: {
            onRegistrarTurno(sTipo){
                this.$router.push({
                    name: "VerificarTurno",
                    params: { sTipoTurno: sTipo }
                })
            },
        },
    }
</script>
<style lang="scss">
    .text-azul {
        color: #003743;
    }
    .imagen-icono {
        width: 32px;
    }
    .no-shadow-card {
        box-shadow: none !important;
    }
</style>