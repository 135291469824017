<template>
  <div>
    <v-row justify="center" v-if="!bErrorPermisos">
      <v-col v-if="bCargando" cols="12" class="mt-5" >
        <v-progress-linear indeterminate color="titlesdark darken-2" />
        <v-skeleton-loader
          v-bind="attrs"
          type="article, actions"
        ></v-skeleton-loader>

        <v-skeleton-loader
          v-bind="attrs"
          type="table-heading, list-item-two-line, image, table-tfoot"
        ></v-skeleton-loader>
      </v-col>
      <v-card v-else class="my-5 mx-5">
        <v-card-text class="pa-2">
          <v-row class="no-gutters">
            <v-row class="no-gutters" >
              <v-col sm="12" :cols="campo.cols"  v-for="(campo, index) in aFormulario" :key="index +campo.AsValorEnviar +nKeyCleanCampos"  class="pa-1 ma-0" v-show="onValidarSubFormulario(campo)">
                <!-- Validamos si es el boton de envio o no -->
                <template v-if="campo.Tipo=='boton'">
                  <v-btn 
                      @click="onEnviarFormulario(campo)" 
                      :class="['white--text', 'my-5']" 
                      color="#63A9E7" 
                      block
                      :disabled="bEnviandoFormulario"
                    >
                      <!-- Cambiar el texto del botón si está cargando -->
                      <template v-if="bEnviandoFormulario">Cargando...</template>
                      <template v-else>Enviar</template>
                    </v-btn>
                </template>
                
                <component 
                  :key="nKeyCleanCampos"
                  v-else-if="onValidarSubFormulario(campo)"
                  :ref="`form-${campo.AsValorEnviar}`"
                  :is="campo.Tipo" 
                  v-model="oFormCampos[campo.AsValorEnviar]"
                  :sLabel="getEquivalencia(campo.Label)"
                  :bObligatorio="campo.Obligatorio == 1 ? true : false"
                  :sCondicion="campo.Condicion"
                  :sCampoFiltro="campo.Filtro"
                  :nFiltro="campo.nFiltro"
                  :valorFiltro="campo.ValorFiltro"
                  :sCampoModificaA="campo.ModificaA"
                  :sCampoItem="campo.Campo"
                  :sIdItem="campo.idValorSeleccionar"
                  @modificaA="onModificarCampo"
                  :sTabla="campo.sTabla"
                  :oDataExtra = "campo"
                  />
              </v-col>
            </v-row>
          </v-row>
        </v-card-text>
        <!-- <v-card-actions class="pa-1" v-if="aCampos.length>0">
          <v-row justify="center" class="no-gutters my-2 pa-1">
            <v-col cols="12" class="mb-2">
              <v-divider></v-divider>
            </v-col>
            <v-col cols="12" class="text-center" >
              <v-btn rounded outlined class="mx-1"  color="btncolor" @click="onLimpiarCampos" :disabled="btnEnable">Limpiar</v-btn>
              <v-btn class="mx-1" color="primary" @click="onSalvar" rounded :disabled="btnEnable">Guardar</v-btn>
            </v-col>
          </v-row> 
        </v-card-actions> -->
      </v-card>
      <modalError v-model="bModalError" :aListaErrores="aListaErrores"/>
      <ModalMensaje v-model="oModalMensaje.bModalVisible" :sMensaje="oModalMensaje.sMensaje" :nTipo="oModalMensaje.nTipo"/>
    </v-row>
    <v-row justify="center" v-else>
      <v-alert 
        color="red"
        icon="mdi-alert-outline"
        class="mt-5"
        type="error"
      > Los permisos de GPS no son correctos </v-alert>
    </v-row>
  </div>
</template>

<script>

import selector from '@/components/Form/Componentes/FormSelect.vue';
import texto from '@/components/Form/Componentes/FormTexto.vue';
import textoArea from '@/components/Form/Componentes/FormTextArea.vue';
import numero from '@/components/Form/Componentes/FormNumero.vue';
import tituloLabel from '@/components/Form/Componentes/FormLabelTitulo.vue';
import copiaCorreo from '@/components/Form/Componentes/FormCorreo.vue';
import descripcionLabel from '@/components/Form/Componentes/FormLabelDescripcion.vue';
import subFormulario from '@/components/Form/Componentes/FormSub.vue';
import foto from '@/components/Form/Componentes/FormFoto.vue';
import firma from '@/components/Form/Componentes/FormFirma.vue';
import modalError from '@/components/Form/Componentes/ModalError.vue';
import GPSLocation from '@/utils/GPSlocation.js';
import HoraFecha from "@/utils/Hora_Fecha.js";
import {mapGetters} from 'vuex'
import APPutils from '@/utils/APPutils.js'


export default {
  name:'Form',
  components: {
    selector,
    texto,
    tituloLabel,
    copiaCorreo,
    descripcionLabel,
    subFormulario,
    foto,
    firma,
    modalError,
    numero,
    textoArea
  },
  props: {
    idMovilApp: {
      type: Number,
      defaul: 0
    }
  },
  data() {
    return {
      bCargando       : true,                            // empleada para indicar que el form esta bCargando
      aFormulario         : [],                               // arreglo de campos del formulario  
      oFormCampos     : {},
      btnEnable       : false,
      aErroresMensaje : [],                               // Arreglo de mensajes para la plataforma,
      tituloForm      : '' , 
      nKeyCleanCampos : 0,                                // Indice que se incrementa para indicar que se limpiaron los campos y que debe refrescar.
      oModalMensaje:{
        bModalVisible:false,
        sMensaje:'',
        nTipo:1
      },
      bMostrarErrores:false,
      aCampos : [],               //Campos de los que se usan en el formulario
      aListaErrores:[],
      bModalError:false,
      oLocation : {},
      bErrorPermisos : false ,
      bEnviandoFormulario:false,
      disabledGuardar:false
    }
  },
  async mounted(){
    // await this.loadDataEdit()
    await this.getGPSLocation();
    await this.getCampos();
    await this.cargarFormulario();
    this.bCargando = false;
  },
  methods: {
    async getGPSLocation(){
      // valido que se tenga permisos de GPS para continuar
      try{
        this.oLocation = await GPSLocation.getCurrentPosition()
        this.bErrorPermisos = false
      }catch(e){
        if(e.message=='User denied Geolocation'){
          this.bErrorPermisos = true
        }
        // Si hay error capturo el objeto segun la Doc https://developer.mozilla.org/en-US/docs/Web/API/GeolocationPositionError
        APPutils.onGuardarLog("Error GeolocationPositionError: {code:"+e.code+", message:"+e.message+"}")
      } 

    },
    async cargarFormulario(){
      //Validamos que venga un idmovil 
      if(this.idMovilApp==0){
        return;
      }
      
      let aFormularios = this.$store.state.dataBase.dataBase.find(item => item.tablaName=='FormularioApp')?.info;
      this.aFormulario = aFormularios?.filter(item=> item.idMovilApp == this.idMovilApp).sort((a, b) => a.Orden - b.Orden);

      for (let i = 0; i < this.aFormulario.length; i++) {
        this.aFormulario[i].cols =  this.aFormulario[i].Tamano; 
        this.aFormulario[i].sTabla =  this.aFormulario[i].Tabla; 
        this.aFormulario[i].Tipo = this.aCampos.find(item => item.idTipoCampoApp == this.aFormulario[i].idTipoCampoApp)?.Tipo;

        //Aprovechasmos el ciclo para agrupar los subFormularios
        if(this.aFormulario[i].idTipoCampoApp=="8"&&(this.aFormulario[i].idSubTipoCampoApp=="0"||this.aFormulario[i].idSubTipoCampoApp==null)){
          //Obtenemos cual es el indentificador del formulario
          let sIdentificadorSub = this.aFormulario[i].ModificaA;

          //Obtenemos todos los subFormularios que tengan dicho identificador
          let aSubFormularios = this.aFormulario.filter(item => item.FiltroSub==sIdentificadorSub);
          this.aFormulario[i].aSubFormulario = aSubFormularios;
        }
      }
    },
    getCampos(){
      this.aCampos = this.$store.state.dataBase.dataBase.find(item => item.tablaName=='TipoCampoApp')?.info;
    },
    onValidarSubFormulario(oItem){
      //Valida si es un subformulario para que no se muestre en el inicio
      if(oItem.idTipoCampoApp=="8"&&oItem.idSubTipoCampoApp!="0"){
        return false;
      }
      return true;
    },
    onLimpiarCampos(){

    },
    onEliminarBase64(obj) {
        // Si el objeto es un array, recorrerlo
        if (Array.isArray(obj)) {
            obj.forEach(item => this.onEliminarBase64(item)); // Llamar la función recursiva para cada elemento
        } else if (typeof obj === 'object' && obj !== null) {
            // Si el objeto es un objeto, recorrer sus propiedades
            for (const key in obj) {
                if (obj.hasOwnProperty(key)) {
                    // Verificar si la propiedad es 'Firma', en ese caso no eliminar nada dentro de ella
                    if (obj?.Firma) {
                        // Recorrer recursivamente dentro de la propiedad Firma sin eliminar el Base64
                        this.onEliminarBase64(obj[key]);
                    } else {
                        // Si encontramos una propiedad que contiene Base64, la eliminamos
                        if (key === 'Base64') {
                            delete obj[key];
                        } else {
                            // Llamar recursivamente en la propiedad del objeto
                            this.onEliminarBase64(obj[key]);
                        }
                    }
                }
            }
        }
    },
    async onEnviarFormulario(oBoton){
      //Validmos el formulario que los campos que son obligatorios esten completos
      this.aListaErrores =  [];
      this.aFormulario.forEach(oFormTemp => {
        let sLabelTemp = this.getEquivalencia(oFormTemp.Label);
        if(oFormTemp.Obligatorio=="1"&&oFormTemp.idSubTipoCampoApp=="0"){
          //Validamos que el campo no este vacio
          if(this.oFormCampos[oFormTemp.AsValorEnviar]==""||this.oFormCampos[oFormTemp.AsValorEnviar]==undefined){
            this.aListaErrores.push(sLabelTemp);
          }
        }
      });

      if(this.aListaErrores.length>0){
        this.bModalError=true;
        return;
      }

      //Agregamos la fecha y las coordenadas
      this.oFormCampos.Fecha = HoraFecha.getNow();
      //Validamos si existe la longitud
      if(this.oLocation?.coords?.latitude){
        this.oFormCampos.Latitud = this.oLocation.coords.latitude;
        this.oFormCampos.Longitud = this.oLocation.coords.longitude;
      }
      else{
        this.oFormCampos.Latitud = 0;
        this.oFormCampos.Longitud = 0;
      }
      this.oFormCampos.nIdUser = this.getnIduser;
      
      //validamos si no mandamos la imagen ya que es por apk
      if(this.getisAPP) {
        this.onEliminarBase64(this.oFormCampos);
      }

      this.bEnviandoFormulario = true;
      let oResult = await this.$axiosServices.postConfiguration(oBoton.ModificaA, this.oFormCampos, true );

      if(this.getisAPP) {
        try {
          if(!Object.prototype.hasOwnProperty.call(oResult, 'nStatusCode')) {
            APPutils.enviarDataOffline(this.oFormCampos,'PWA',oBoton.ModificaA)
            this.disabledGuardar = false
          }else {
            if ((oResult.nStatusCode == 200) || (oResult.nStatusCode == 220)  ){
            APPutils.registro_OK()
            this.oFormCampos = {};
            this.nKeyCleanCampos++;
          } else {
            APPutils.enviarDataOffline(this.oFormCampos,'PWA',oBoton.ModificaA)
          }
          this.disabledGuardar = false
          }
          
        } catch(e) {
          /* eslint-disable no-console */
          console.log(e)
          APPutils.onGuardarLog("Error al enviar formulario:"+e)
          APPutils.enviarDataOffline(this.oFormCampos,'PWA',oBoton.ModificaA)
          this.disabledGuardar = false
        }
        
      }
      else{
        //Validamos si se guardao correctamente
        if(oResult?.sStatus=="success"){
          this.disabledGuardar = false
          this.$swal({
            timer: 3500,
            icon: `success`,
            title: `${oResult.sMessage}`,
            showConfirmButton: false
          })
          this.oFormCampos = {};
          this.nKeyCleanCampos++;
        }
        else{
          this.oModalMensaje.bModalVisible = true
          this.oModalMensaje.sTitulo = 'Error en la peticion' 
          this.oModalMensaje.nTipo = 3;
          this.oModalMensaje.sMensaje = oResult?.sMessage
        }
      }
      this.disabledGuardar = false;
      this.bEnviandoFormulario = false;
      
    },
    onModificarCampo(oDato){
      //Consultamos del formulario el dato al que le vamos a pasar el filtro
      let aFormularioTemp = this.aFormulario.find(item => item.sTabla == oDato.sCampoModificaA);
      if(aFormularioTemp!=undefined){
        aFormularioTemp.nFiltro = oDato.nId;
      }
      
    },
    getEquivalencia(sEquivalencia){
      if(sEquivalencia?.includes("??")){
        sEquivalencia = sEquivalencia.replaceAll("??", "");
        sEquivalencia = this.getoEquivalencia[sEquivalencia];
      }
      return sEquivalencia;
    }
  },
  computed: {
    ...mapGetters(['getlatlng','getoEquivalencia','getimei', 'getnIduser', 'getisAPP'])
  },
}
</script>

<style lang="scss"  scoped>
  .caja-desvanecido{
    position: absolute;
    bottom: 0px;
    left: 0px;
    z-index: 1;
    width: 100%;
    display: flex;
    justify-content: end;
    align-items:flex-end;
    .btn-vermas-infocard{
      margin: 0px 5px 5px 0px;
    }
  }
  .h-minimo-desvanecido{
    background: rgb(255,255,255);
    background: linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 12%, rgba(255,255,255,0.87718837535014) 33%, rgba(255,255,255,0) 100%);
  }
  .h-completo-desvanecido{
    background: none;
  }
  .h-completo{
    height: auto;
    overflow: auto;
    padding: 10px 10px 50px 10px!important;
    .caja-desvanecido{
      height: 40px;
      border-top: solid #e3e3e3 1px;
    }
  }
  .h-minimo{
    height: 200px;
    overflow: hidden;
    padding: 10px 10px 10px 10px!important;
    .caja-desvanecido{
      height: 80px;
      border-top: none;
    }
  }
  .info-text-cardexpander{
    font-size: 0.95em;
    line-height: 1.1em;
    margin: 0px;
    padding: 0px;
  }
  .info-text-cardexpander{
    font-size: 0.95em;
    line-height: 1.1em;
    margin: 0px;
    padding: 0px;
  }
</style>