<template>
  <div>
    <v-dialog
      v-model="valor"
      scrollable 
      persistent 
      :overlay="false"
      max-width="500px">
      
      <v-card >
        <v-toolbar dark color="primary" dense >
          <v-spacer></v-spacer>
          <v-btn icon dark @click="onCerrar" class="btn-close">
            <v-icon>mdi-window-close</v-icon>
          </v-btn>
        </v-toolbar>
        
          <v-col justify="center" align="center">
            <v-row no-gutters justify="center" class="ma-2">
              <lottie-vue-player
                  :src="fAnimaJson"
                  player-size="standard"
                  :player-controls="false"
                  :autoplay="true"
                  :loop="true"
                  style="width: 100%; height:300px"
                >
              </lottie-vue-player>
            </v-row>
            <v-row no-gutters justify="center" class="ma-2">
              <span class="text-semibold">Acerca el celular al punto NFC</span>
            </v-row>
            <v-row no-gutters justify="center" class="ma-2">
              <span class="red--text" v-if="sMsgError != ''">{{ sMsgError }}</span>
            </v-row>
          </v-col>  
      </v-card>
    </v-dialog>  
  </div>
</template>

<script>
import APPutils from '@/utils/APPutils.js'
import IconoNfc from "@/assets/ic_nfc.json"

export default {
  name: 'LecturaNFC',
  components: {
  },
	props: {
      activo: {
        type: Boolean,
        default: false
      },
			onLecturaCompletada: Function
    },
  data () {
		return {
      camera: 'off',
      sMsgError: '',
      valor: true,
      isLecturaNFC: false,
      fAnimaJson:JSON.stringify(IconoNfc),
    }
	},
	mounted() {
		this.onLeerNFC();
	},
  created(){
    vm=this;
  },
  destroyed() {
    APPutils.onDetenerLeerNFC();
  },
  methods: {
    onNfcLeido (sCodigo) {
      if(sCodigo!=undefined){
        this.onLecturaCompletada(sCodigo)
        this.valor = false
        this.$emit('cerrar');
      }
    },
    onLeerNFC(){
      if(typeof IApp !== 'undefined'){
        this.valor = false
        APPutils.onLeerNFC();
      }else{
        this.valor = true
        this.onInicializarDetector()
      }
    },
    onCerrar(){
      this.$emit('cerrar');
    },
    async onInicializarDetector(){
      if (!("NDEFReader" in globalThis)) {
        this.sMsgError = "Este navegador no soporta lectura de NFC"
      } 
      else {
        try {
          const ndef = new NDEFReader();
          await ndef.scan();
          ndef.addEventListener("reading", (resultado) => {
            const tagId = this.convertHexToDecimal(resultado.serialNumber); // Obtiene el ID del NFC en HEX
            console.log(`NFC Tag ID (Hex):${resultado.serialNumber}, (Dec): ${tagId}`);
            this.onNfcLeido(`${tagId}`)
          });
        } catch (error) {
          this.sMsgError = `Error NFC: ${error.message}`;
        }
      }
    },
    convertHexToDecimal(hexString) {
      const bytes = hexString.split(':');

      // Invertir el orden de los bytes (little-endian)
      const reversedBytes = bytes.reverse().join('');

      // Convertir el string hexadecimal a un número decimal
      const decimalValue = parseInt(reversedBytes, 16);
      return decimalValue;
    }
  }
}
var vm = null;

window.onNfcLeido = function(sCodigo) {
  vm.onNfcLeido(sCodigo);
}

window.onCancelarLectura = function() {
  vm.onCerrar();
}


</script>
<style lang="scss" scoped>
.algo{
  position: relative;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  background: rgba($color: #000000, $alpha: .5);
}
.btn-cerrar{
  position: absolute;
  top: 5px;
  right: 3px;
  z-index: 1;
}
.btn-linterna{
  font-size: 70px !important;
}
.linterna{
  height: 30vh !important;
  position: absolute;
  z-index: 1;
}
</style>