import ControlActividadesUtils from '@/utils/ControlActividadesUtils.js'
import axiosServices from '@/services/axiosServices.js'
import store from '@/store/index.js'
import STR from '@/assets/strings.js'
import { mapGetters } from 'vuex'

export default {
    data() {
        return {
            oAtributos: {},
            oConfiguracion: {}
        }
    },
    methods: {
        async onValidarUsuario(sCedula,sTipoTurno){ // Consulta la existencia de un operador y si realizo el turno
            const sParams = `cedula=${sCedula}&turno=${sTipoTurno}&app=PWA&sm=${store.state.sTipoApp}`
            const oRSP = await axiosServices.getConfiguration('/turnos/validar_usuario', true, sParams)
            return (oRSP) ? oRSP : []
        },
        async onRegistrarTurno(oBody, aFotos=[], bIsApp=false){ // Agrega el turno
            const sParams = `&app=PWA&sm=${store.state.sTipoApp}`
            const oRSP = await axiosServices.postConfiguration('/turnos', oBody, true, sParams )
            // envio las fotos que tego guardadas en aFotos si no estoy con APP
            if(!bIsApp){
              aFotos.forEach( async foto => {
                // se recorren objetos todos tienenl a foto en base64, pero si es firma tienen el nombre en Firma y si es foto el nombre esta en NombreFoto
                if(('NombreFoto' in foto)){
                  axiosServices.postFoto(`${foto.NombreFoto}`, foto.Base64)
                }
                if(('Firma' in foto)){
                  axiosServices.postFoto(`${foto.Firma}`, foto.Base64)
                }
              })
            }
            return (oRSP) ? oRSP : [];
        },
        onCargarVistaRegistrar(aData,sTipoTurno,router){ // Carga la vista del formulario registrar
            store.dispatch('setaDataOperario', aData)
            router.push({
                name: "RegistrarTurno",
                params: { sTipoTurno: sTipoTurno }
            })
        },
        onValidarTituloRegistro(sTipoTurno, oDataOperario) { // Genera el titulo de manera dinamica de acuerdo al caso de registro de turno
            const sEstadoOdataOperario = Object.keys(oDataOperario).length > 0 ? 'lleno' : 'vacio'
            const oTitulos = {
                'Arribo-lleno': STR.ttlTurnoEntrada,
                'Arribo-vacio': STR.ttlNuevaEntrada,
                'Salida-lleno': STR.ttlTurnoSalida,
                'Salida-vacio': STR.ttlNuevaSalida,
            }
            return oTitulos[`${sTipoTurno}-${sEstadoOdataOperario}`] || ''
        },
        onGetClientes(){ // Extrae del store los clientes
            return ControlActividadesUtils.onGetDataTabla('Clientes')
        },    
        onGetClienteDefault(){ // Otiene el Cliente de SeifModulos
            return this.getsTipoApp == 1 ? this.onGetClientes()[0].idCliente : ''
        },
        onGetSedeDefault(){ // Otiene la sede de SeifModulos
            return this.getsTipoApp == 1 ? store.state.nIDSedeBase.toString() : ''
        },
    },
    computed: {
        ...mapGetters(['getisAPP','getsTipoApp'])
    },
    created() {
        this.oAtributos= {}
        this.oConfiguracion={}
        const oAtribbs = {
            cedula: { 
                placeholder: STR.msjCedulaCiudadania,
                filled:true,
                "append-icon":"mdi-pencil-outline"
            },
            nombre: { 
                placeholder: STR.msgNombre,
            },
            cliente: { 
                placeholder: STR.msgCliente,
                readonly : this.getsTipoApp == 1,
                filled : this.getsTipoApp == 1,
                clearable: this.getsTipoApp != 1,
            },
            sede: { 
                placeholder: STR.msgSede,
                readonly : this.getsTipoApp == 1,
                filled : this.getsTipoApp == 1,
                clearable: this.getsTipoApp != 1,
            },
            puesto: { 
                placeholder: STR.msgPuesto,
            },
            novedad: { 
                placeholder: STR.msgNovedad,
            },
        }
        const oConfiguraciones = {
            sTbSede : this.getsTipoApp == 1 ? 'Sede' : this.getisAPP ? 'Sede' : 'sede',
            sCampoSede : 'Nombre_Sede', // sCampoSede : this.getisAPP == 1 ? 'Nombre_Sede' : 'Nombre',
            sIdSede : 'idSede', // sIdSede : sTipoApp == 1 ? 'idSede' : 'idsede',
            sIdClienteSede : 'idCliente' // sTipoApp == 1 ? 'Cliente_idCliente' : 'idCliente',
        }
        Object.assign(this.oAtributos,oAtribbs)
        Object.assign(this.oConfiguracion,oConfiguraciones)
    },
}