<template>
     <v-container class="custom-alert">
        <v-col no-gutters class="pa-2">
            <h3 class="font-weight-medium text-azul">{{ $STR.lblDatosAsociados }}</h3>
        </v-col>
        <div class="d-flex flex-no-wrap">
            <v-avatar class="ma-1" size="75" tile>
                <v-img src="/img/icons/perfil-Turnos-512x512.png"></v-img>
            </v-avatar>
            <div>
                <v-card-title class="text-h6" v-text="getaDataOperario[0]?.NombreOperario"/>
                <v-card-subtitle v-text="codificarCadena(getaDataOperario[1])"/>
            </div>
        </div>
    </v-container>
</template>
<script>
    import { mapGetters } from 'vuex'
    export default {
        name: "DatosAsociados",
        computed: {
            ...mapGetters(['getaDataOperario']),
        },
        methods: {
            codificarCadena(sCedula) { // Agrega asteriscos a la cedula
                const primerCaracter = sCedula[0]
                const ultimoCaracter = sCedula[sCedula.length - 1]
                const numAsteriscos = sCedula.length - 2
                return primerCaracter + '*'.repeat(numAsteriscos) + ultimoCaracter
            }
        }
    }
</script>
<style lang="scss" scoped>
    .custom-alert {
        border-left:3px solid #00A7C6;   
    }
</style>
