import Vue from "vue"
import VueRouter from "vue-router"
import store from "@/store/index.js"
import firebase from "firebase/app"
import Conexion from "@/utils/Conexion.js"

// Vistas para rutas
import Home from "@/views/Home.vue"
import DescargaDB from "@/views/DescargaDB.vue"
import LoginHome from "@/views/LoginHome.vue"
import ElementosDashboard from "@/views/elementos-qr/dashboard.vue"
import ElementosRegistro from "@/views/elementos-qr/RegistroElementos.vue"
import ListaDinamicos from "@/views/ListaDinamicos.vue"
import Dinamicos from "@/components/dinamicos/Dinamicos.vue"
import APPutils from "@/utils/APPutils.js"
import Pendientes from "@/views/Pendientes.vue"
import PqrCliente from "@/views/pqr/PqrCliente"
import PqrInicioTicket from "@/views/pqr/PqrInicioTicket"
import NuevoTicket from "@/views/pqr/NuevoTicket"
import ConsultarTicket from "@/views/pqr/ConsultarTicket"
import contextoIntExt from "@/views/riesgos/contextoIntExt"
import DashboardRondas from "@/views/control-actividades/rondas/Rondas.vue"
import PuntosPorRonda from "@/views/control-actividades/rondas/PuntosPorRonda.vue"
import ListaVerificacionPunto from "@/views/marcaciones/ListaVerificacionPunto.vue"
import FinalizarRondaIncompleta from "@/views/control-actividades/rondas/FinalizarRondaIncompleta.vue"
import ReporteNovedadIncidente from "@/views/marcaciones/ReporteNovedadIncidente.vue"
import ReporteMarcacion from "@/views/marcaciones/ReporteMarcacion.vue"
import VerificarTurno from "@/views/control-actividades/turnos/VerificarTurno.vue"
import RegistrarTurno from "@/views/control-actividades/turnos/RegistrarTurno.vue"

// import inifirebase from '@/firebase/inifirebase'; 

Vue.use(VueRouter)

const routes = [
  {
    path: "/",
    name: "LoginHome",
    component: LoginHome,
  },
  {
    path: "/Home",
    name: "Home",
    component: Home,
  },
  {
    path: "/DescargaDB",
    name: "DescargaDB",
    component: DescargaDB,
  },
  {
    path: "/ListaDinamicos",
    name: "ListaDinamicos",
    component: ListaDinamicos,
  },
  {
    path: "/elementos-qr",
    name: "ElementosDashboard",
    component: ElementosDashboard,
  },
  {
    path: "/elementos-qr/registro",
    name: "ElementosRegistro",
    component: ElementosRegistro,
  },
  {
    path: "/Dinamicos/:idFormulario",
    name: "Dinamicos",
    component: Dinamicos,
  },
  {
    path: "/Dinamicos",
    name: "DinamicosApp",
    component: Dinamicos,
  },
  {
    path: "/Pendientes",
    name: "Pendientes",
    component: Pendientes,
  },
  {
    path: "/pqrCliente",
    name: "pqrcliente",
    component: PqrCliente,
  },
  {
    path: "/pqrInicioTicket",
    name: "pqriniciotickte",
    component: PqrInicioTicket,
  },
  {
    path: "/nuevoTicket",
    name: "nuevoticket",
    component: NuevoTicket,
  },
  {
    path: "/consultarTicket",
    name: "consultarticket",
    component: ConsultarTicket,
  },
  {
    path: "/DashboardRondas",
    name: "DashboardRondas",
    component: DashboardRondas,
  },
  {
    path: "/PuntosPorRonda/:idRonda",
    name: "PuntosPorRonda",
    component: PuntosPorRonda,
  },
  {
    path: "/ListaVerificacionPunto/:idRonda/:codigo",
    name: "ListaVerificacionPunto",
    component: ListaVerificacionPunto,
  },
  {
    path: "/FinalizarRondaIncompleta/:idRonda",
    name: "FinalizarRondaIncompleta",
    component: FinalizarRondaIncompleta,
  },
  {
    path: "/ReporteNovedadIncidente",
    name: "ReporteNovedadIncidente",
    component: ReporteNovedadIncidente,
  },
  {
    path: "/ReporteMarcacion/:idRonda?/:sCodigo?",
    name: "ReporteMarcacion",
    component: ReporteMarcacion,
  },
  {
    path: "/PWA/Riesgos/contextoIntExt",
    name: "contextoIntExt",
    component: contextoIntExt,
  },
  {
    path: "/VerificarTurno/:sTipoTurno",
    name: "VerificarTurno",
    component: VerificarTurno,
  },
  {
    path: "/RegistrarTurno/:sTipoTurno",
    name: "RegistrarTurno",
    component: RegistrarTurno,
  },
]

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
})

router.beforeEach ( async (to, from, next) => {
  // Manejo de Firebase para reportar ingresos
  let oLog = {
    page_location: window.location.origin+"/"+to.fullPath,
    page_path: to.fullPath,
    page_title: to.name
  }
  firebase.analytics().logEvent("page_view", oLog)
  // Manejo de Subdominio
  let sSubdomain = ""
  if (typeof window.location.host.split(".")[0] !== "undefined") {
    if (window.location.host.split(".")[0].includes("localhost")) {
      sSubdomain = "desarrollo"
    } else {
      sSubdomain = window.location.host.split(".")[0]
    }
  } else {
    sSubdomain = "desarrollo"
  }
  store.dispatch("saveSubDomain", sSubdomain)
  store.dispatch('savebMenuBlock', false);

  // Valido si estoy en PWA normal o APP , para esto valido si la variabe IApp existe es decir no es undefinided
  if(typeof IApp !== 'undefined') {    // eslint-disable-line
    const sSubDominioApp = APPutils.getSubDominio()
    store.dispatch('setimei', APPutils.getIMEI() )
    //store.dispatch('setnIDSedeBase', APPutils.onGetIdSede())
    store.dispatch('saveSubDomain', sSubDominioApp)
    store.dispatch('saveIdFormulario', APPutils.getIdFormulario() ) // En este punto, se solicita a la App el idFormulario para guardarlo antes de cargar las vistas y asi este disponible en todo momento
    store.dispatch('setLogin', true)
    store.dispatch('setisAPP', true)
    store.dispatch('savebMenuBlock', true);
    console.log("urlActual:"+store.state.sUrlBase);
    //Conexion.onValidarUrlBase(true)
  }else{
    // Caso contrario, ingresamos desde WEB validamos la url base
    Conexion.onValidarUrlBase() 
  }
  console.log(to.fullPath,'to.fullPath');

  //Validamos si es una ruta de pwa para cargar la informacion de los formularios dinamicos de la pwa
  if (to.fullPath.includes("/PWA/")) {
    if(typeof IApp !== 'undefined') {    // eslint-disable-line
      await APPutils.getTablasPWA()
    }
    next();
  }
  
  switch (to.name) {
    // En cae se ponene los router a eludir el login
    case "LoginHome" : {
      next()
      break
    }
    case "Dinamicos" : {
      if(typeof IApp !== 'undefined') {    // eslint-disable-line
        Conexion.onValidarUrlBase(true)
        await APPutils.getInfo()
      }
      next()
      break
    }
    case "DinamicosApp" : {
      if(typeof IApp !== 'undefined') {    // eslint-disable-line
        Conexion.onValidarUrlBase(true)
        await APPutils.getInfo()
      }
      next()
      break
    }
    case "ElementosDashboard" : {
      if(typeof IApp !== 'undefined') {    // eslint-disable-line
        Conexion.onValidarUrlBase(true)
        APPutils.getInfoElementos()
      }
      next()
      break
    }
    case "DashboardRondas" : {
      if(typeof IApp !== 'undefined') {    // eslint-disable-line
        Conexion.onValidarUrlBase(true)
        await APPutils.getInfoControlActividades()
      }
      next()
      break
    }
    case "VerificarTurno":{
      if(typeof IApp !== 'undefined') {    // eslint-disable-line
        Conexion.onValidarUrlBase(true)
        await APPutils.getInfoTurnos()
      }
      next()
      break
    }
    case "pqrcliente" :
      // Para las rutas de PQRClienteFinal siempre se fuerza a validar la obtencion del la url de api ya que no existe proceso de login
      Conexion.onValidarUrlBase(true)
      store.dispatch('savebMenuBlock', true);
      store.dispatch('setLogin', true);
      next()
    break
    case "pqriniciotickte" :
    case "nuevoticket" :
    case "consultarticket" : {
      Conexion.onValidarUrlBase(true)
      store.dispatch('savebMenuBlock', true);
      store.dispatch('setLogin', true);
      if (Object.keys(store.state.oPqrInfo).length === 0) {
        next("/pqrCliente")
      } else {
        next()
      }
      break
    }
    default: {
      if (!store.state.login) {
        next("/")
      } else {
        next()
      }
      break
    }
  }
})

export default router
