<template>
<div>
  <span :class="claseError">{{sLabel}}</span>
  <span v-if="bObligatorio" class="font-weight-bold ml-1 red--text">*</span>
    <v-autocomplete
      class="mt-1"
      v-model="valor"
      v-bind="oAtributos"
      :disabled="onValidarDeshabilitado"
      :items="aData"
      :item-text="sCampoItem"
      :item-value="sIdItem"
      :placeholder="sPlaceholder"
      :loading="loadSelect"
      height="30px"
      :clearable="bClearable"
      dense
      :error="esError"
      :rules="noVacio"
      hide-details
    >
      <template slot="no-data">
        <div>No hay información</div>
      </template>
    </v-autocomplete>
</div>
</template>

<script>


export default {
  name: "FormSelect",
  components: {
  },
  props: {
    value: { },
    oAtributos:{
        type:Object,
        default:()=>{}
    },
    bObligatorio:{
        type: Boolean,
        default: false,
    },
    sLabel:{
        type: String,
        default: "",
    },
    sTabla:{
        type: String,
        default: "",
    },
    sIdItem:{
      type: String,
      default: "id",
    },
    sCampoItem:{
      type: String,
      default: "texto",
    },
    aItemsData:{
      type: Array,
      default: ()=>[],
    },
    sCampoModificaA:{
      type: String,
      default: "",
    },
    sCampoFiltro:{
      type: String,
      default: "",
    },
    nFiltro:{
      type: String,
      default: "-1",
    },
    bClearable:{
      type: Boolean,
      default: true,
    },
    sPlaceholder:{
      type: String,
      default() {
        return this.sTabla; 
      }
    },
  },
  data() {
    return {
      valor: this.value,
      itemsData: [],
      loadSelect: false,
      Mensaje: "Cargando..",
      esError: false,
      noVacio: [(v) => !!v],
      bEditable: this.editable == "0" ? true : false,
      aData: [],
      aDataTemp:[],
      claseError:""
    };
  },
  mounted() {
    this.getDatosTabla();
  },
  methods: {
    getDatosTabla(){
      let aDataTemp = this.$store.state.dataBase.dataBase.find(item => item.tablaName==this.sTabla)?.info;
      //si la tabla no la en cuentra probablemente debe estar en la tablasGenerales
      if(aDataTemp==undefined){
        aDataTemp = [];
        let aTablaGeneral = this.$store.state.dataBase.dataBase.find(item => item.tablaName=="TablasGeneralesApp")?.info;
        if(aTablaGeneral!=undefined){
          aDataTemp = aTablaGeneral.filter(item => item.NombreTabla==this.sTabla);
        }
      }

      aDataTemp.sort((a, b) => {
        // Cambia "Campo" por el nombre del campo por el que deseas ordenar
        if (a[this.sCampoItem] < b[this.sCampoItem]) return -1;
        if (a[this.sCampoItem] > b[this.sCampoItem]) return 1;
        return 0;
      });
      

      this.aData = aDataTemp;
      this.aDataTemp = this.aData;
    },
  },
  computed: {
    onValidarDeshabilitado(){
      if(this.sCampoFiltro!="0"&&this.sCampoFiltro!=""&&this.nFiltro=="-1"){
        return true;
      }
      return false;
    }
  },
  watch: {
    valor(NewValor) {
      this.$emit('input', NewValor);
      this.$emit('modificaA',{sCampoModificaA:this.sCampoModificaA,nId:NewValor,sIdItem:this.sIdItem})
      // let oDato  = this.aData.find((elemento) => elemento[this.sIdItem] == NewValor);
      if (NewValor !== this.valor) {
        this.valor = newValue;
      }
    },
    value(NewValor){
      this.valor = NewValor
    },
    nFiltro(newFiltro){
      this.aData = this.aDataTemp.filter(item=> item[this.sCampoFiltro]==newFiltro);
    }
  },
};
</script>

<style scoped>
</style>